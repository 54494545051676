<template>
  <div>
    <p>
      Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy
      nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut
      wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit
    </p>
    <br /><br />
    <div class="space-between align-center">
      <div class="align-center flex-1">
        <div class="filter-icon">
          <img src="/img/filter.svg" alt="" />
        </div>

        <div class="search-wrappper mt-2 mb-2 mt-md-0 mb-md-0">
          <b-input-group size="sm">
            <b-form-input v-model="filter" placeholder="Search here...">
            </b-form-input>

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>

      <div class="p-relative">
        <button
          type="button"
          class="btn mulai  font-bold"
          @click="buatType"
        >
          <fa-icon icon="plus-circle" class="mr-1" /> BUAT TYPE
        </button>
      </div>
    </div>
    <br /><br />

    <!-- Main table element -->
    <b-table
      show-empty
      stacked="md"
      :busy="isBusy"
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      @filtered="onFiltered"
    >
      <template v-slot:cell(actions)="row">
        <b-button
          size="sm"
          class="mr-3 action-button"
          variant="outline-secondary"
          @click="handleEdit(row.item)"
        >
          <fa-icon icon="pencil-alt" class="mr-1" />Edit
        </b-button>
        <b-button
          size="sm"
          class="mr-3 action-button"
          variant="outline-secondary"
          @click="handleDelete(row.item)"
        >
          <fa-icon icon="trash-alt" class="mr-1" /> Delete
        </b-button>

        <!-- <b-button
          size="sm"
          class="mr-3 action-button pub-unpub"
          variant="outline-secondary"
          v-if="row.item.published"
          @click="row.item.published = !row.item.published"
        >
          <fa-icon icon="minus-circle" class="mr-1" /> Unpublish
        </b-button>

        <b-button
          size="sm"
          class="mr-3 action-button pub-unpub"
          variant="outline-secondary"
          v-else
          @click="row.item.published = !row.item.published"
        >
          <fa-icon icon="circle" class="mr-1" /> Publish
        </b-button> -->
      </template>
    </b-table>

    <b-row>
      <b-col md="6" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>

    <b-modal
      ref="modal-add"
      id="modal-add"
      hide-footer
      size="lg"
      title="Buat Type Baru"
      centered
      v-model="addModal"
    >
      <div class="mr-22">
        <label>Nama Type</label>
        <br />
        <b-form-input
          placeholder="Nama .. "
          v-model="inputData.name"
        ></b-form-input>
        <br />
        <label>Deskripsi</label>
        <b-form-input
          placeholder="Deskripsi Singkat .. "
          v-model="inputData.description"
        ></b-form-input>
        <br />
        <div class="txt-right">
          <b-button
            class="e-button bg-color2 e-button--not-rounded"
            disabled
            v-show="showLoading"
          >
            <b-spinner small></b-spinner>
            <span class="sr-only">Loading...</span>
          </b-button>
          <button
            class="e-button bg-color2 e-button--not-rounded"
            v-show="!showLoading"
            @click="addType"
          >
            SIMPAN
          </button>
        </div>
        <br />
      </div>
    </b-modal>

    <b-modal
      ref="modal-add"
      id="modal-add"
      hide-footer
      size="lg"
      title="Edit Type"
      centered
      v-model="editModal"
    >
      <div class="mr-22">
        <label>Nama Type</label>
        <br />
        <b-form-input
          placeholder="Nama .. "
          v-model="inputData.name"
        ></b-form-input>
        <br />
        <label>Deskripsi</label>
        <b-form-input
          placeholder="Deskripsi Singkat .. "
          v-model="inputData.description"
        ></b-form-input>
        <br />
        <div class="txt-right">
          <b-button
            class="e-button bg-color2 e-button--not-rounded"
            disabled
            v-show="showLoading"
          >
            <b-spinner small></b-spinner>
            <span class="sr-only">Loading...</span>
          </b-button>
          <button
            class="e-button bg-color2 e-button--not-rounded"
            v-show="!showLoading"
            @click="editType"
          >
            SIMPAN
          </button>
        </div>
        <br />
      </div>
    </b-modal>

  </div>
</template>

<script>
import { mapActions } from "vuex";
import * as types from "@/store/types";

export default {
  data() {
    return {
      showLoading: false,
      isBusy: true,
      selectId: null,
      items: [], 
      fields: [
        { key: "no", label: "No" },
        {
          key: "name",
          label: "Nama Type",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "description",
          label: "Deskripsi",
          sortable: true,
          class: "text-center"
        },
        { key: "actions", label: "Action" }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      sortBy: null,
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      showPopup: false,
      inputData: {
        id: null,
        name: "",
        description: ""
      },
      addModal: false,
      editModal: false
    };
  },

  mounted() {
    this.totalRows = this.items.length;
    this.typeList();
  },

  methods: {
    ...mapActions({
      getTypeList: types.GET_MARKETING_TYPE_LIST,
      deleteType: types.DELETE_MARKETING_TYPE,
      createType: types.CREATE_MARKETING_TYPE,
      updateType: types.UPDATE_MARKETING_TYPE
    }),
    typeList(level_id = null) {
      this.isBusy = true;
      this.getTypeList()
        .then(response => {
          var res = response;
          this.items = [];
          for (var i = 0; i < res.length; i++) {
            var dataItem = {
              no: i + 1,
              id: res[i].id,
              name: res[i].name,
              description: res[i].description
            };
            this.items.push(dataItem);
          }
          this.totalRows = res.length;
          this.isBusy = false;
        })
        .catch(error => {
          this.toastError('b-toaster-top-center', error);
        });
    },
    handleEdit(item) {
      this.inputData = {
        id: item.id,
        name: item.name,
        description: item.description
      }
      this.editModal = true;
    },
    editType() {
      this.showLoading=true;
      this.updateType(this.inputData)
      .then(response => {
        if(response) {
          this.showLoading=false;
          this.toastSuccess('b-toaster-top-center', "Berhasil update");
          this.editModal = false;
          this.typeList();
        } else {
          this.showLoading=false;
          this.toastError('b-toaster-top-center', "Gagal update");
        }
      })
      .catch(error => {
        this.showLoading=false;
        this.toastError('b-toaster-top-center', error);
      });
    },
    handleDelete(item) {
      this.$bvModal
        .msgBoxConfirm("Delete type?", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            this.deleteType(item)
            .then(response => {
              this.toastSuccess('b-toaster-top-center', "Data dihapus");
              this.typeList();
            })
            .catch(error => {
              this.toastError('b-toaster-top-center', error);
            })
          }
        })
        .catch(err => {
          this.toastError('b-toaster-top-center', err);
        });
    },
    toastSuccess(toaster, message, append = false) {
      this.$bvToast.toast(message, {
        title: 'Notification',
        toaster: toaster,
        solid: true,
        variant: 'success',
        autoHideDelay: 3000
      })
    },
    toastError(toaster, error, append = false, ) {
      this.$bvToast.toast(error, {
        title: 'Error',
        toaster: toaster,
        solid: true,
        variant: 'danger',
        autoHideDelay: 3000
      })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    buatType() {
      this.inputData = {
        id: null,
        name: "",
        description: ""
      }
      this.addModal = true
    },
    addType() {
      this.showLoading=true;
      this.createType(this.inputData)
      .then(response => {
        if(response) {
          this.showLoading=false;
          this.toastSuccess('b-toaster-top-center', "Type baru berhasil ditambah");
          this.addModal = false;
          this.typeList();
        } else {
          this.showLoading=false;
          this.toastError('b-toaster-top-center', "Gagal menambah data baru");
        }
      })
      .catch(error => {
        this.showLoading=false;
        this.toastError('b-toaster-top-center', error);
      });
    }
  }
};
</script>

<style>
.search-wrapper {
  width: 100%;
  max-width: 222px;
}

.filter-icon {
  margin-right: 11px;
  background-color: #e9e9e9;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  text-align: center;
  padding-top: 7px;
}

.action-button:hover {
  background-color: #f08045;
  border: 1px solid #f08045;
}

.product-popup {
  position: absolute;
  z-index: 99;
  right: 0;
  top: -88px;
  width: 316px;
  height: 70px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #f08045;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 4px;
  display: flex;
}

.product-popup__item {
  flex: 1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 500;
  color: #494949;
  flex-direction: column;
}

.product-popup__item:hover {
  background-color: #ffe1d2;
}

.product-popup-arrow {
  position: absolute;
  z-index: 999;
  right: 99px;
  top: -19px;
  width: 14px;
}

.search-wrappper {
  min-width: 160px;
}

.pub-unpub {
  min-width: 105px;
}
</style>
